@import './variable';
@import '~font-awesome/css/font-awesome.min.css';
@import '~bootstrap/scss/bootstrap';
@import 'react-month-picker/scss/month-picker.scss';
@import './components/header/Header.scss';
@import './components/menu/CheeseburguerMenu.scss';
@import './components/Avatar/Avatar.scss';
@import './components/templates/ListagemPage.scss';
@import './components/table/Datatable.scss';
@import '~react-bootstrap-table/dist/react-bootstrap-table.min.css';
@import './components/HorarioFuncionamento/HorarioFuncionamento.scss';
@import './components/LocaleHorarioAtendimento/LocaleHorarioAtendimento.scss';
@import './components/HorarioAtendimentoExames/HorarioAtendimentoExames.scss';
@import './components/card/CustomCard.scss';
@import './components/FullCustomCard/FullCustomCard.scss';
@import './components/Toasts/toast.scss';
@import './components/inputs/FormSelectInput.scss';
@import './components/inputs/CheckBoxInput.scss';
@import './components/inputs/DatePicker.scss';
@import './components/inputs/FormTextCKEditor.scss';
@import './pages/agenda/AgendaFiltro.scss';
@import './pages/agenda/Indisponibilidade/Indisponibilidade.scss';
@import './pages/Historico/Historico.scss';
@import './components/Indisponibilidade/IndisponibilidadeRow.scss';
@import './pages/agenda/AgendaPage.scss';
@import './pages/tarefas/TarefasPage.scss';
@import './pages/paciente/atendimentos/Atendimento.scss';
@import './pages/prontuario/ProntuarioPage.scss';
@import './pages/prontuario/Anamnese.scss';
@import './pages/prontuario/new/css/Prontuario.scss';
@import './pages/prontuario/new/css/HeaderProntuario.scss';
@import './pages/cadastroWizard/components/MultiStepProgressBar.css';
@import './pages/cadastroWizard/CadastroWizard.css';
@import './pages/agendaOnline/AgendaOnline.scss';
@import 'components/dashboard/DashboardComponent';
@import './components/calendar/Calendar.scss';
@import './components/DadosUser/DadosUser.scss';
@import './components/FolhaCard/FolhaCard.scss';
@import './components/MedicamentoPrescrito/MedicamentoPrescrito.scss';
@import './components/BarraFiltro/BarraFiltro.scss';
@import './components/Modal/ModalCrud.scss';
@import './components/Modal/ModalAnexos.scss';
@import './components/Modal/ModalSolutiOtp.scss';
@import './components/Modal/ModalImpressao.scss';
@import './components/Modal/ModalProtocolo.scss';
@import './components/Modal/ModalGerarDocumentos.scss';
@import './components/Modal/ModalLocal.scss';
@import './components/Modal/ModalConsultaAvulsa.scss';
@import './components/Modal/ModalTermoAceite.scss';
@import './components/Modal/ModalCids.scss';
@import './default.scss';
@import './forms.scss';
@import './alert.scss';
@import './components/MenuFull/Menu.scss';
@import './components/Topo/TopoListagem.scss';
@import './components/table/Datagrid.scss';
@import './components/countNumber/DumbNumber.css';
@import './components/inputs/CheckboxMultiSelect.scss';

// @import './index.css';
