.Select {
  font-family: $font-family-default;
  background-color: $branco;
  color: $preto;
  font-size: $font-default;
  border-radius: $border-radius;
  // margin-bottom: 10px;
}
.Select .react-select__control {
  background-color: $branco;
  border: 2px solid $branco;
  border-radius: $border-radius;
  //min-height: 60px;
  box-shadow: none !important;
  cursor: pointer;
  border: 1px solid $cinza1;
}

.Select.select-barra .react-select__control {
  min-height: 50px;
  height: 50px;
}
.Select .react-select__control:hover {
  // border: 1px solid $cinza2;
}
.Select .react-select__control--is-focused {
  // border: 1px solid $cinza2 !important;
}
.Select .react-select__value-container {
  // font-size: $font-default !important;
  padding: 2px 0 0;
}

// VALOR
.Select .react-select__single-value {
  width: 99%;
  margin: 0 0 0 5px;
  white-space: pre-wrap;
}

// SELECT
.Select .css-b8ldur-Input {
  margin: 0;
  padding: 0 0 6px;
  height: 100%;
}

.Select .css-26l3qy-menu {
  z-index: 10;
  text-transform: capitalize;
}
.Select .react-select__input {
  height: 100%;
  margin: 0 0 0 20px;
  padding-top: 3px;
}
.Select .react-select__input input {
  height: 90%;
  margin: auto 0;
  border: 0 !important;
}
// Input multiplo
.Select .react-select__value-container--is-multi .react-select__input {
  margin: 0 0 0 10px;
}

// ICONES E SEPARADORES
.Select .react-select__indicators span {
  background: none;
}
// Icone Select
.Select .react-select__dropdown-indicator {
  padding: 8px 8px 8px 4px;
}
.Select .react-select__dropdown-indicator svg {
  width: 20px;
  height: 15px;
}

// Icone Limpar
.Select .react-select__clear-indicator {
  padding: 0;
  cursor: pointer;
}
.Select .react-select__clear-indicator svg {
  width: 14px;
  height: 14px;
  color: $danger;
}

// PLACEHOLDER
.Select .react-select__placeholder {
  color: $cinza4;
  font-weight: 200;
  margin: 0 0 0 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 88%;
}

// INVALIDO
.Select.is-invalid {
  border: 0px solid red !important;
  box-shadow: none !important;
  border-radius: 0px;
  background-color: transparent;
}
.Select.is-invalid .react-select__dropdown-indicator {
  color: red;
}

// OPÇÕES - SINGLE e MULTIPLO
.react-select__menu {
  color: $preto;
  border: 1px solid $cinza2;

  margin: 4px 0 !important;
  padding: 4px 10px;
  cursor: pointer;
  z-index: 1000;
}
.patient .react-select__menu {
  z-index: 100;
}
.react-select__option {
  border: none !important;
  cursor: pointer !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.select-wrap .react-select__option {
  border: none !important;
  cursor: pointer !important;
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: initial;
}
.patient .react-select__option {
  height: 60px;
}

.patient .react-select__menu-list .react-select__option .create {
  padding: 0 20px;
}

// Create new patient
.patient .react-select__menu-list div:first-child .react-select__option {
  color: $primary;
  border-radius: $border-radius;
}
.patient .react-select__menu-list div:first-child .react-select__option:hover {
  background-color: $branco;
  text-decoration: underline;
}
.patient
  .react-select__menu-list
  div:first-child
  .react-select__option
  .optionCreateUser {
  display: none !important;
  margin: 0 0 0 6px;
}

.react-select__option:hover {
  background-color: $cinza1;
  border-radius: $border-radius;
}
.react-select__option:active {
  background-color: $branco !important;
  color: $primary !important;
}

// .react-select__option:last-child {
//   background-color: $branco !important;
//   color: $primary !important;
//   font-weight: bold;
// }

.react-select__menu .react-select__option--is-focused {
  background-color: $cinza1;
  color: $preto;
  border-radius: $border-radius;
}
.react-select__menu .react-select__option--is-selected {
  background-color: $branco !important;
  color: $primary !important;
  border-radius: $border-radius;
}

// SEM RESULTADOS
.react-select__menu-notice {
  font-size: $font-default;
  font-weight: 200;
  color: $cinza4 !important;
  padding: 2px !important;
}

// OPÇÕES - CHECKBOX
.checkbox.control.control--checkbox:first-child {
  font-weight: bolder;
  background-color: $cinza1;
  border-radius: $border-radius-min;
}
.checkbox.control.control--checkbox .react-select__option input {
  display: none;
  margin: 0;
}

// TAG
.Select .react-select__multi-value {
  background-color: $branco;
  border: 1px solid $cinza2;
  border-radius: 0px;
  padding: 0px 4px 1px;
  margin: 0px 6px 0px 0px;
}
.Select .react-select__multi-value .css-12jo7m5 {
  font-size: $font-default;
  font-weight: 600;
}

.Select .react-select__multi-value__remove {
  margin-right: 5px;
  content: url('./images/icon_x_red.svg');
  width: 15px;
  float: left;
  margin-right: 0px;
  margin-left: 0px;
  margin-top: 0px;
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.Select .customOption {
  // white-space: nowrap;
  overflow: hidden;
  // text-overflow: ellipsis;
  max-width: 90%;
  margin: 0 0 0 6px;
  text-transform: uppercase;
}
.Select .select-especialidades.customOption {
  font-size: 10px;
  line-height: 12px;
}
.Select .select-nome.customOption {
  font-size: 13px;
  line-height: 15px;
  font-weight: bold;
}

.Select .select-conselho.customOption {
  font-size: 11px;
}

.Select .medicamentos.customOption {
  text-overflow: ellipsis;
  max-width: 80%;
}

.patient .Select .optionCreateUser {
  margin-left: 6px;
}

// DISABLE
.disabled,
.react-select--is-disabled {
  opacity: 0.5;
  filter: grayscale(1);
  cursor: default;
  background-color: transparent;
}
.disabled,
.react-select--is-disabled .react-select__control {
  background: transparent;
}
.disabled,
.react-select--is-disabled .react-select__placeholder {
  color: $preto;
  font-style: italic;
}
.disabled,
.react-select--is-disabled .react-select__indicators {
  display: none;
}

//  Media Mobile
@media (max-width: 1023px) {
  .patient .react-select__menu-list .avatarCustom.avatar-list {
    width: 40px;
    height: 40px;
  }
  .patient .react-select__menu-list .avatarCustom.avatar-list span {
    font-size: $font-default;
  }
  .patient .Select .customOption {
    max-width: 90%;
    margin-left: 10px;
  }
  .patient .Select .optionCreateUser {
    margin-left: 10px;
  }
  .patient
    .react-select__menu-list
    div:last-child
    .react-select__option
    .create {
    padding: 0;
  }
}
